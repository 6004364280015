import { Wrapper } from './RichText.styles';

type RichTxtProps = {
  content: string;
  isAboutSection?: boolean;
}

export const RichText = ({
  content,
  isAboutSection,
}: RichTxtProps) => (
  <Wrapper
    dangerouslySetInnerHTML={{ __html: content }}
    $isAboutSection={isAboutSection}
  />
);
