import { useIntl } from 'react-intl';

import { ArrowExternal } from '@/core/components/Icons';
import {
  Button,
  ButtonVariant,
} from '@/core/components/Button';
import { globalMessages } from '@/core/messages/global.messages';

import { getBackendImage } from '@/utils/helpers';

import {
  Logo,
  LogoWrapper,
  SliderItemWrapper,
  Subtitle,
  Tile,
  Title,
  Wrapper,
} from './SliderItem.styles';
import { SliderItemInterface } from '../../sections.types';

type SliderItemProps = {
  item: SliderItemInterface;
}

export const SliderItem = ({
  item: {
    link,
    title,
    description,
    backgroundImage,
    logo,
  },
}: SliderItemProps) => {
  const { formatMessage } = useIntl();

  return (
    <SliderItemWrapper>
      <Wrapper image={getBackendImage(backgroundImage.data?.attributes.url)}>
        <Tile>
          {logo.data && (
            <LogoWrapper>
              <Logo
                src={getBackendImage(logo.data.attributes.url)}
                alt={logo.data.attributes.alternativeText || 'logo'}
              />
            </LogoWrapper>
          )}
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
          <Button
            variant={ButtonVariant.PRIMARY}
            endIcon={<ArrowExternal />}
            renderAs="a"
            label={formatMessage(globalMessages.readMore)}
            href={link}
            target="_blank"
          />
        </Tile>
      </Wrapper>
    </SliderItemWrapper>
  );
};
