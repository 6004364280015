import styled from 'styled-components';

import { Caption } from '@/core/components/Typography';

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
`;

export const RowText = styled.span`
  ${Caption};
  color: ${({ theme }) => theme.colors.neutral[700]};
`;
