import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';

import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@/core/components/Icons/Icons.stories';
import { ButtonVariant } from '@/core/components/Button';
import { ThemeDirection } from '@/core/constants/constants';

import { SliderItemInterface } from '../sections.types';
import {
  ScrollLeftButton,
  ScrollRightButton,
  ScrollWrapper,
  Wrapper,
} from './Slider.styles';
import { SliderItem } from './SliderItem';

interface SliderProps {
  components: Array<SliderItemInterface>;
}

export const Slider = ({ components }: SliderProps) => {
  const theme = useTheme();

  const wrapper = useRef<HTMLDivElement>(null);

  const isSliderActive = useMemo(() => components.length > 1, [components.length]);

  const {
    windowDimensions: { width },
    isTabletOrAbove,
  } = useGlobalContext();

  const [
    isRightButtonVisible,
    setIsRightButtonVisible,
  ] = useState(theme.direction === ThemeDirection.LTR);

  const [
    isLeftButtonVisible,
    setIsLeftButtonVisible,
  ] = useState(theme.direction !== ThemeDirection.LTR);

  const [
    imageWidth,
    setImageWidth,
  ] = useState(0);

  const [
    gap,
    setGap,
  ] = useState(0);

  const scrollMiniatures = useCallback((scrollOffset: number) => {
    const currentScrollLeft = wrapper.current?.scrollLeft || 0;

    wrapper.current?.scroll({
      behavior: 'smooth',
      left: currentScrollLeft % scrollOffset !== 0 ?
        Math.ceil(currentScrollLeft / scrollOffset) * scrollOffset :
        currentScrollLeft + scrollOffset,
    });
  }, [wrapper.current]);

  useLayoutEffect(
    () => {
      setImageWidth((wrapper.current?.firstChild as Element)?.getBoundingClientRect().width);
      setGap(wrapper?.current ?
        Number(window.getComputedStyle(wrapper?.current).getPropertyValue('column-gap')
          .slice(0, -2)) :
        0);
    },
    [width]
  );

  useEffect(() => {
    const scrollListener = () => {
      const {
        scrollLeft = 0,
        clientWidth = 0,
        scrollWidth = 0,
      } = wrapper?.current || {};

      const leftVisibility = theme.direction === ThemeDirection.LTR ?
        scrollLeft !== 0 :
        -1 * scrollLeft + clientWidth < scrollWidth;

      const rightVisibility = theme.direction === ThemeDirection.LTR ?
        scrollLeft + clientWidth < scrollWidth :
        scrollLeft !== 0;

      if (isTabletOrAbove || components.length > 2 || !(leftVisibility && rightVisibility)) {
        setIsLeftButtonVisible(leftVisibility);
        setIsRightButtonVisible(rightVisibility);
      }
    };

    wrapper?.current?.addEventListener('scroll', scrollListener);

    return () => wrapper.current?.removeEventListener('scroll', scrollListener);
  }, [
    wrapper.current,
    theme.direction,
  ]);

  return (
    <Wrapper>
      {isSliderActive && (
        <>
          {isRightButtonVisible && (
            <ScrollRightButton
              icon={<ArrowRightIcon />}
              variant={ButtonVariant.SECONDARY}
              onClick={() => scrollMiniatures(imageWidth + gap)}
              ariaLabel="scroll right"
              $areBothButtonsVisible={isLeftButtonVisible && isRightButtonVisible}
            />
          )}
          {isLeftButtonVisible && (
            <ScrollLeftButton
              icon={<ArrowLeftIcon />}
              variant={ButtonVariant.SECONDARY}
              onClick={() => scrollMiniatures(-imageWidth - gap)}
              ariaLabel="scroll left"
              $areBothButtonsVisible={isLeftButtonVisible && isRightButtonVisible}
            />
          )}
        </>
      )}
      <ScrollWrapper ref={wrapper}>
        {components.map(item => (
          <SliderItem
            key={item.id}
            item={item}
          />
        ))}
      </ScrollWrapper>
    </Wrapper>
  );
};
