import styled from 'styled-components';

import { Subhead } from '@/core/components/Typography/Typography.styles';

import { TitleAlign } from '@/features/Sections/sections.types';

import { breakpoint } from '@/themes/breakpoints';

import { Button } from '../Button';

export const Wrapper = styled.section<{ backgroundColor?: string }>`
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;

  @media ${breakpoint.md} {
    padding: 80px 0;
  }
  background-color: ${({
    backgroundColor,
    theme: { colors },
  }) => backgroundColor || colors.white};
`;

export const Subheader = styled.p<{ hasLink?: boolean; align?: TitleAlign }>`
  ${Subhead};
  width: 100%;
  height: 16px;
  color: ${({ theme: { colors } }) => colors.primaryBlue[900]};
  text-align: center;

  @media ${breakpoint.sm} {
    height: 28px;
    font-weight: ${({ theme }) => theme.fontWeight.semiMedium};
    text-align: ${({
    hasLink,
    align,
  }) => (hasLink ? 'start' : align)};
  }
`;

export const TitleWrapper = styled.div<{ hasLink?: boolean; align?: TitleAlign }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({
    hasLink,
    align,
  }) => (hasLink ? 'space-between' : align)};
  align-items: center;
  margin-bottom: 32px;

  @media ${breakpoint.sm} {
    flex-direction: row;
    margin-bottom: 40px;
  }
`;

export const Title = styled.h2`
  font-family: var(--executive-website-heading-font-family);
  margin-block: 0.5rem;
  text-align: center;
`;

export const ReadMoreButton = styled(Button)`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;
