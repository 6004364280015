import Link from 'next/link';
import { useTheme } from 'styled-components';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@/core/components/Icons/Icons.stories';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { ThemeDirection } from '@/core/constants/constants';

import { InsightsItemInterface } from '@/features/Sections/sections.types';

import { getBackendImage } from '@/utils/helpers';

import {
  BackgroundWrapper,
  ContentWrapper,
  Description,
  Icon,
  IconWrapper,
  ImageWrapper,
  InsightsImage,
  Title,
} from './InsightsItem.styles';

type InsightsItemProps = Omit<InsightsItemInterface, 'id'>;

export const InsightsItem = ({
  title,
  description,
  link,
  image,
}: InsightsItemProps) => {
  const theme = useTheme();

  return (
    <BackgroundWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
      <Link
        href={link}
        title={`link to ${title}`}
      >
        <IconWrapper>
          <Icon
            icon={theme.direction === ThemeDirection.LTR ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            variant={ButtonVariant.SECONDARY}
            ariaLabel="got to link"
          />
        </IconWrapper>
      </Link>
      <ImageWrapper>
        <InsightsImage
          src={getBackendImage(image.data?.attributes.url)}
          alt={image.data?.attributes.alternativeText ||
            `Reem Al Hashimy - ${title}`
          }
        />
      </ImageWrapper>
    </BackgroundWrapper>
  );
};
