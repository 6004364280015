import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTheme } from 'styled-components';

import { ArticleMiniature } from '@/core/components/ArticleMiniature';
import { routePaths } from '@/core/constants/routes';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from '@/core/components/Icons/Icons.stories';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';
import { ThemeDirection } from '@/core/constants/constants';

import { FeaturedArticleItemData } from '@/features/Articles/Articles.types';

import {
  ArticleLink,
  MiniatureWrapper,
  ScrollLeftButton,
  ScrollRightButton,
  ScrollWrapper,
  Wrapper,
} from './FeaturedArticles.styles';

interface FeaturedArticlesProps {
  components: Array<FeaturedArticleItemData>;
}

export const FeaturedArticles = ({
  components,
}: FeaturedArticlesProps) => {
  const wrapper = useRef<HTMLDivElement>(null);

  const {
    windowDimensions: { width },
    isTabletOrAbove,
  } = useGlobalContext();
  const theme = useTheme();

  const [
    isRightButtonVisible,
    setIsRightButtonVisible,
  ] = useState(theme.direction === ThemeDirection.LTR);

  const [
    isLeftButtonVisible,
    setIsLeftButtonVisible,
  ] = useState(theme.direction !== ThemeDirection.LTR);

  const [
    imageWidth,
    setImageWidth,
  ] = useState(0);

  const [
    gap,
    setGap,
  ] = useState(0);

  const scrollMiniatures = useCallback((scrollOffset: number) => {
    const currentScrollLeft = wrapper.current?.scrollLeft || 0;

    wrapper.current?.scroll({
      behavior: 'smooth',
      left: currentScrollLeft % scrollOffset !== 0 ?
        Math.ceil(currentScrollLeft / scrollOffset) * scrollOffset :
        currentScrollLeft + scrollOffset,
    });
  }, [wrapper.current]);

  useLayoutEffect(
    () => {
      setImageWidth((wrapper.current?.firstChild as Element)?.getBoundingClientRect().width);
      setGap(wrapper?.current ?
        Number(window.getComputedStyle(wrapper?.current).getPropertyValue('column-gap')
          .slice(0, -2)) :
        0);
    },
    [width]
  );

  useEffect(() => {
    const scrollListener = () => {
      const {
        scrollLeft = 0,
        clientWidth = 0,
        scrollWidth = 0,
      } = wrapper?.current || {};

      const leftVisibility = theme.direction === ThemeDirection.LTR ?
        scrollLeft !== 0 :
        -1 * scrollLeft + clientWidth < scrollWidth;

      const rightVisibility = theme.direction === ThemeDirection.LTR ?
        scrollLeft + clientWidth < scrollWidth :
        scrollLeft !== 0;

      setIsLeftButtonVisible(leftVisibility);
      setIsRightButtonVisible(rightVisibility);
    };

    wrapper?.current?.addEventListener('scroll', scrollListener);

    return () => wrapper.current?.removeEventListener('scroll', scrollListener);
  }, [wrapper.current]);

  return (
    <Wrapper>
      {isTabletOrAbove && isRightButtonVisible && (
        <ScrollRightButton
          icon={<ArrowRightIcon />}
          variant={ButtonVariant.SECONDARY}
          onClick={() => scrollMiniatures(imageWidth + gap)}
          ariaLabel="scroll right"
        />
      )}
      {isTabletOrAbove && isLeftButtonVisible && (
        <ScrollLeftButton
          icon={<ArrowLeftIcon />}
          variant={ButtonVariant.SECONDARY}
          onClick={() => scrollMiniatures(-imageWidth - gap)}
          ariaLabel="scroll left"
        />
      )}
      <ScrollWrapper ref={wrapper}>
        {components.map(({
          id,
          attributes: {
            image,
            title,
            subtitle,
            slug,
            topics,
            createdAt,
            readingTime,
          },
        }) => (
          <MiniatureWrapper key={id}>
            <ArticleLink href={`${routePaths.vision}/${slug}`}>
              <ArticleMiniature
                image={image}
                title={title}
                subtitle={subtitle}
                topic={topics.data[0]}
                createdAt={createdAt}
                readingTime={readingTime}
              />
            </ArticleLink>
          </MiniatureWrapper>
        ))}
      </ScrollWrapper>
    </Wrapper>
  );
};
