/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
import styled from 'styled-components';

import { Subhead } from '@/core/components/Typography';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
`;

export const VideoWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 50%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const NavigationWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const DescriptionWrapper = styled.div`
  flex-grow: 1;
`;

export const Description = styled.p`
  ${Subhead}
  overflow: hidden;
  display: -webkit-box;
  max-width: calc(100vw - 144px);
  margin: 0;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiMedium};
  text-align: center;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
