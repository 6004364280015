import styled from 'styled-components';

import { Button } from '@/core/components/Button';
import { Caption } from '@/core/components/Typography/Typography.styles';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 40px;
  gap: 8px;

  @media ${breakpoint.sm} {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 60px;
    gap: 22px;
  }

  @media ${breakpoint.md} {
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 80px;
    gap: clamp(
      21px,
      (100vw - ${sizes.tablet.minWidth}px) / ${sizes.desktopLarge.minWidth},
      24px
    );
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ViewMoreButton = styled(Button)`
  height: 100%;
  cursor: pointer;
`;

export const ItemTitleParagraph = styled.p`
  ${Caption}
  margin-top: 8px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-style: normal;
  font-family: var(--executive-website-regular-font-family);
`;
