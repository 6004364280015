import styled from 'styled-components';

import { Color } from '@/core/interfaces/common';
import { BodyMedium } from '@/core/components/Typography';
import { Grid } from '@/core/components/Grid';

import { backgroundVariant } from '@/utils/backgroundVariant';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled(Grid)`
  grid-auto-flow: dense;
  row-gap: 32px;
  margin-inline: 0;
`;

export const ImageWrapper = styled.div<{ $isReversed: boolean }>`
  grid-column-start: 1;
  grid-column-end: 5;

  @media ${breakpoint.sm} {
    grid-column-start: ${({ $isReversed }) => ($isReversed ? 5 : 1)};
    grid-column-end: ${({ $isReversed }) => ($isReversed ? 9 : 5)};
  }

  @media ${breakpoint.md} {
    grid-column-start: ${({ $isReversed }) => ($isReversed ? 7 : 1)};
    grid-column-end: ${({ $isReversed }) => ($isReversed ? 13 : 7)};
  }
`;

export const Image = styled.img`
  width: 100%;
  height: max-content;
  max-height: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
`;

export const HeaderWrapper = styled.div<{ $color: Color }>`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  gap: 8px;
  ${backgroundVariant}

  @media ${breakpoint.sm} {
    padding: 40px;
  }
`;

export const ContentWrapper = styled.div<{ $isReversed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-column-start: 1;
  grid-column-end: 5;

  @media ${breakpoint.sm} {
    gap: 40px;
    grid-column-start: ${({ $isReversed }) => ($isReversed ? 1 : 5)};
    grid-column-end: ${({ $isReversed }) => ($isReversed ? 5 : 9)};
  }

  @media ${breakpoint.md} {
    grid-column-start: ${({ $isReversed }) => ($isReversed ? 1 : 8)};
    grid-column-end: ${({ $isReversed }) => ($isReversed ? 6 : 13)};
  }
`;

export const Content = styled.div`
  padding: 0 16px;

  @media ${breakpoint.sm} {
    padding: 0 40px;
  }
`;

export const Title = styled.h4`
  margin: 0;
  font-family: var(--executive-website-heading-font-family);
`;

export const Description = styled.p`
  ${BodyMedium}
  margin: 0;
  text-align: justify;
`;
