import { defineMessages } from 'react-intl';

export const paginationMessages = defineMessages({
  next: {
    defaultMessage: 'Next',
    description: 'next page button',
    id: 'pagination.next',
  },
  previous: {
    defaultMessage: 'Previous',
    description: 'Previous page button',
    id: 'pagination.previous',
  },
});
