import styled from 'styled-components';

import {
  BodySmall,
  H5,
} from '@/core/components/Typography/Typography.styles';

export const Wrapper = styled.div`
  img {
    filter: grayscale(70%);
    width: 100%;
    transition: filter 0.5s ease;
  }

  &:hover {
    img {
      filter: none;
    }
  }
`;

export const ArticleImage = styled.img`
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: cover;
  object-position: 50% 50%;
`;

/* stylelint-disable property-no-vendor-prefix, value-no-vendor-prefix */
export const Title = styled.h5`
  ${H5};
  overflow: hidden;
  display: -webkit-box;
  font-family: var(--executive-website-heading-font-family);
  margin-block-start: 24px;
  margin-block-end: 0;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
/* stylelint-enable property-no-vendor-prefix, value-no-vendor-prefix */

export const Subtitle = styled.p`
  ${BodySmall}
  margin-block: 8px;
  overflow: hidden;
  color: ${({ theme: { colors } }) => colors.neutral[700]};
  text-overflow: ellipsis;
  white-space: nowrap;
`;
