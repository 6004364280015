import { RichText } from '@/core/components/RichText';
import { HoverImageWrapper } from '@/core/components/HoverImageWrapper';

import { getBackendImage } from '@/utils/helpers';

import {
  Content,
  ContentWrapper,
  Description,
  HeaderWrapper,
  Image,
  ImageWrapper,
  Title,
  Wrapper,
} from './AboutPageSection.styles';
import { AboutPageItemInterface } from '../sections.types';

export const AboutPageSection = ({
  content,
  isReversed,
  image,
  header,
  description,
  backgroundColor,
}: AboutPageItemInterface) => (
  <Wrapper>
    <ImageWrapper $isReversed={isReversed}>
      <HoverImageWrapper>
        <Image
          src={getBackendImage(image.data?.attributes.url)}
          alt={image.data?.attributes.alternativeText ||
            `Reem Al Hashimy - ${header}`}
        />
      </HoverImageWrapper>
    </ImageWrapper>
    <ContentWrapper $isReversed={isReversed}>
      <HeaderWrapper $color={backgroundColor}>
        <Title>
          {header}
        </Title>
        <Description>
          {description}
        </Description>
      </HeaderWrapper>
      {content && (
        <Content>
          <RichText
            content={content}
            isAboutSection
          />
        </Content>
      )}
    </ContentWrapper>
  </Wrapper>
);
