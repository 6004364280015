import { DataType } from '@/core/interfaces/common';

import {
  ArticleTopic,
  FeaturedArticleInterface,
} from '@/features/Articles/Articles.types';

import { getBackendImage } from '@/utils/helpers';

import {
  ArticleImage,
  Subtitle,
  Title,
} from './ArticleMiniature.styles';
import { HoverImageWrapper } from '../HoverImageWrapper';
import { ArticleCaption } from '../ArticleCaption';

type ArticleMiniatureProps = Omit<FeaturedArticleInterface, 'id' | 'featured' | 'topics' | 'slug'> &
  { topic: DataType<ArticleTopic> };

export const ArticleMiniature = ({
  image,
  title,
  subtitle,
  topic,
  createdAt,
  readingTime,
}: ArticleMiniatureProps) => (
  <HoverImageWrapper>
    <ArticleImage
      src={getBackendImage(image?.data?.attributes.url)}
      alt={image?.data?.attributes.alternativeText ||
        title}
    />
    <Title>{title}</Title>
    <Subtitle>{subtitle}</Subtitle>
    <ArticleCaption
      date={createdAt}
      topic={topic?.attributes.name}
      readingTime={readingTime}
    />
  </HoverImageWrapper>
);
