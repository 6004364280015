import { MomentId } from '@/features/Moments/moments.types';
import { MomentImage } from '@/features/Sections/Moments';

import { getBackendImage } from '@/utils/helpers';

import {
  Grid,
  Moment,
} from './MomentsGallery.styles';

type MomentsGalleryProps = {
  data: Array<MomentId>;
}

export const MomentsGallery = ({ data }: MomentsGalleryProps) => (
  <Grid>
    {data.map(moment => {
      const {
        attributes: {
          image,
          title,
        },
      } = moment;

      return (
        <Moment>
          <MomentImage
            src={getBackendImage(image?.data?.attributes?.url)}
            alt={image?.data.attributes?.alternativeText || title}
          />
        </Moment>
      );
    })}
  </Grid>
);
