import styled from 'styled-components';

import { IconButton } from '@/core/components/IconButton';
import { ThemeDirection } from '@/core/constants/constants';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  margin-right: ${({ theme }) => (theme.direction === ThemeDirection.RTL ? `-${sizes.phone.margin}px` : 0)};
  margin-bottom: -40px;
  margin-left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? `-${sizes.phone.margin}px` : 0)};

  @media ${breakpoint.sm} {
    margin-right: ${({ theme }) => (theme.direction === ThemeDirection.RTL ? `-${sizes.tablet.margin}px` : 0)};
    margin-bottom: 0;
    margin-left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? `-${sizes.tablet.margin}px` : 0)};
    padding: 0 32px;
  }

  @media ${breakpoint.md} {
    width: auto;
    margin-left: 0;
    padding: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ScrollWrapper = styled.div`
  overflow: scroll;
  display: flex;
  width: calc(100% + ${sizes.phone.margin}px);
  padding-inline-end: ${sizes.phone.margin}px;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 0;

  div {
    scroll-snap-align: start;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoint.sm} {
    gap: 32px;
    width: calc(100% + ${sizes.tablet.margin}px);
    padding-inline-end: ${sizes.tablet.margin}px;
  }

  @media ${breakpoint.md} {
    gap: 48px;
    width: calc(100% + ${sizes.desktop.margin}px);
    padding-inline-end: ${sizes.desktop.margin}px;
  }

  @media ${breakpoint.lg} {
    width: calc(100% + ${sizes.desktopLarge.margin}px);
    padding-inline-end: ${sizes.desktopLarge.margin}px;
  }
`;

const ScrollButton = styled(IconButton)`
  position: absolute;
  bottom: 80px;
  z-index: 2;

  @media ${breakpoint.sm} {
    bottom: 234.5px;
  }

  @media ${breakpoint.md} {
    bottom: 310px;
  }
`;

export const ScrollLeftButton = styled(ScrollButton)<{ $areBothButtonsVisible: boolean }>`
  left: ${({ $areBothButtonsVisible }) => ($areBothButtonsVisible ? 'calc(50% - 62px)' : 'calc(50% - 28px)')};
  background-color: ${({ theme: { colors } }) => colors.white};

  @media ${breakpoint.sm} {
    left: ${sizes.tablet.margin}px;
  }

  @media ${breakpoint.md} {
    left: -28px;
  }
`;

export const ScrollRightButton = styled(ScrollButton)<{ $areBothButtonsVisible: boolean }>`
  right: ${({ $areBothButtonsVisible }) => ($areBothButtonsVisible ? 'calc(50% - 62px)' : 'calc(50% - 28px)')};
  background-color: ${({ theme: { colors } }) => colors.white};

  @media ${breakpoint.sm} {
    right: ${sizes.tablet.margin}px;
  }

  @media ${breakpoint.md} {
    right: -28px;
  }
`;
