import styled from 'styled-components';

import {
  BodyMedium,
  H5,
} from '@/core/components/Typography/Typography.styles';
import { HoverImageWrapper } from '@/core/components/HoverImageWrapper';

import { breakpoint } from '@/themes/breakpoints';

export const TextWrapper = styled.div<{ color: string; offset: number }>`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 24px;
  background-color: ${({ color }) => color};

  @media ${breakpoint.xs} {
    width: 50%;
  }

  @media ${breakpoint.md} {
    position: absolute;
    bottom: ${({ offset }) => `${-offset - 32}px`};
    opacity: ${({ offset }) => (offset === 0 ? 0 : 1)};
    width: 100%;
    transition:
      opacity 0.3s 0.3s,
      bottom 0.3s ease;
  }
`;

export const AboutImage = styled.img`
  width: 100%;
  transition: filter 0.5s ease;
  object-fit: cover;
  object-position: 0 0;
  aspect-ratio: 3/4;

  @media ${breakpoint.xs} {
    width: 50%;
    max-height: 100%;
    object-position: 50% 50%;
  }

  @media ${breakpoint.md} {
    width: 100%;
  }
`;

export const Wrapper = styled(HoverImageWrapper)<{ index: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${breakpoint.xs} {
    flex-direction: ${({ index }) => (index % 2 === 0 ? 'row' : 'row-reverse')};
  }

  @media ${breakpoint.md} {
    overflow: hidden;
    flex-direction: row;
    width: 33%;

    &:hover {
      ${TextWrapper} {
        position: absolute;
        bottom: 0;
      }
    }
  }
`;

export const Title = styled.h5`
  ${H5}
  font-family: var(--executive-website-heading-font-family);
  line-height: 116.667%;
  margin-block: 0;
`;

export const LongText = styled.p`
  ${BodyMedium}
`;
