/* stylelint-disable order/properties-order */
import styled from 'styled-components';

import { MomentVectorCard } from '@/core/components/MomentVectorCard';
import { Caption } from '@/core/components/Typography/Typography.styles';

import { breakpoint } from '@/themes/breakpoints';

export const SingleBlockContainer = styled.button<{ $isMarginTopZero?: boolean }>`
  position: relative;
  height: 100%;
  border: none;
  padding: 0;
  background-color: transparent;
  text-align: unset;
  cursor: pointer;

  &:nth-child(3n) {
    margin-top: 10px;
  }

  &:nth-child(2n) {
    margin-top: -5px;
  }

  &:nth-child(4n) {
    margin-top: 20px;
  }

  &:hover {
    filter: none;
  }
`;

export const MomentImage = styled.img`
  width: 100%;
  height: max-content;
  max-height: 100%;
  aspect-ratio: 3/4;
  object-fit: cover;
  filter: grayscale(70%);
  transition: filter 0.5s ease;

  @media ${breakpoint.sm} and (hover: hover) {
    &:hover {
      filter: none;
    }
  }
`;

export const StyledMomentVectorCard = styled(MomentVectorCard)`
  svg {
    width: 100%;
    max-height: 100%;
    aspect-ratio: 3/4;
    object-fit: cover;
  }
`;

export const ImageDescription = styled.p`
  ${Caption}
  color: ${({ theme }) => theme.colors.neutral[700]};
  font-family: var(--executive-website-regular-font-family);
  margin: 0;
  margin-top: 8px;
  margin-bottom: 5px;
`;
