/* stylelint-disable no-descending-specificity */
import styled, { css } from 'styled-components';

import {
  BodyMedium,
  BodySmall,
  H2,
  H3,
  H4,
  H5,
  H6,
  Quote,
} from '@/core/components/Typography';
import { ThemeDirection } from '@/core/constants/constants';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.div<{ $isAboutSection?: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media ${breakpoint.md} {
    padding-bottom: 80px;
  }

  > p:last-of-type {
    margin-bottom: 0;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  h2 {
    ${H2}
    margin: 0;
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? '8px' : '24px')};
    font-family: var(--executive-website-heading-font-family);
  }

  h3 {
    ${H3}
    margin: 0;
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? '8px' : '24px')};
    font-family: var(--executive-website-heading-font-family);
  }

  h4 {
    ${H4}
    margin: 0;
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? '8px' : '24px')};
    font-family: var(--executive-website-heading-font-family);
  }

  h5 {
    ${H5}
    margin: 0;
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? '8px' : '24px')};
    font-family: var(--executive-website-heading-font-family);
  }

  h6 {
    ${H6}
    margin: 0;
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? '8px' : '24px')};
    font-family: var(--executive-website-heading-font-family);
  }

  p {
    ${BodyMedium}
    display: flex;
    flex-direction: column;
    margin: 0;
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? 0 : '24px')};
    color: ${({ theme: { colors } }) => colors.neutral[700]};
    text-align: justify;
  }

  img {
    ${({ $isAboutSection }) => ($isAboutSection ?
    css`
      filter: grayscale(70%);
      align-self: center;
      width: 240px;
      height: max-content;
      margin-top: 32px;
      margin-bottom: 32px;
      max-height: 100%;
      aspect-ratio: 4/3;
      transition: filter 0.5s ease;
      object-fit: cover;

      @media ${breakpoint.sm} and (hover: hover) {
        &:hover {
          filter: none;
        }
      }
      ` :
    css`
      width: calc(100vw - 32px);
      height: max-content;
      max-height: 100%;

      @media ${breakpoint.sm} {
        width: 522px;
      }

      @media ${breakpoint.md} {
        width: 628px;
      }

      @media ${breakpoint.lg} {
        width: 868px;
      }
    `)};
  }

  ul,
  ol {
    margin: 0;
  }

  li {
    ${BodyMedium}
  }

  blockquote {
    ${Quote}
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 24px;
    margin-inline-end: 24px;

    p {
      ${({ $isAboutSection }) => ($isAboutSection ?
    css`
      font-size: 24px;
      line-height: 116.667%;
      ` :
    css`
      font-size: 36px;
      line-height:  122.222%;

      @media ${breakpoint.md} {
        font-size: 48px;
        line-height:  116.667%;
      }

      @media ${breakpoint.lg} {
        font-size: 32px;
        line-height:  125%;
      }
     `)};
    }

    &::before {
      content: '';
      position: relative;
      top: 16px;
      right: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? '24px' : 'auto')};
      left: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? 'auto' : '24px')};
      display: flex;
      width: 16px;
      height: 14px;
      background-image: url("/assets/images/quote.svg");
      background-size: cover;
    }
  }

  hr {
    margin-bottom: ${({ $isAboutSection }) => ($isAboutSection ? '8px' : '24px')};
    border-color: ${({ theme: { colors } }) => colors.neutral[100]};
  }

  a {
    display: inline-block;
    color: ${({ theme: { colors } }) => colors.neutral[600]};
    ${BodySmall}
    line-height: 171.429%;
    text-decoration-line: underline;
  }

  table {
    width: 100%;
    max-width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    tr {
      td {
        height: 64px;
        border-bottom: 1px solid ${({ theme: { colors } }) => colors.neutral[300]};
        padding: 8px;
        background-color: ${({ theme: { colors } }) => colors.neutral[100]};

        a {
          margin-bottom: 0;
        }

        p {
          ${BodySmall}
          margin-bottom: 0;
          color: ${({ theme: { colors } }) => colors.neutral[600]};
          line-height: 171.429%;
        }
      }

      &:first-of-type {
        td {
          height: 48px;
          border-bottom: none;
          background-color: ${({ theme: { colors } }) => colors.neutral[200]};

          p {
            color: ${({ theme: { colors } }) => colors.black};
          }
        }
      }
    }
  }
`;
