import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const HoverImageWrapper = styled.div`
  @media ${breakpoint.sm} and (hover: hover) {
    img {
      filter: grayscale(70%);
      transition: filter 0.5s ease;
    }

    &:hover {
      img {
        filter: none;
      }
    }
  }
`;
