import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';

import { Modal } from '@/core/components/Modal';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { globalMessages } from '@/core/messages/global.messages';
import { routePaths } from '@/core/constants/routes';
import { Color } from '@/core/interfaces/common';

import { MomentId } from '@/features/Moments/moments.types';
import { insertVariantCardsMoments } from '@/features/Moments/helpers';

import { getBackendImage } from '@/utils/helpers';

import {
  ButtonWrapper,
  ViewMoreButton,
  Wrapper,
} from './Moments.styles';
import { Moment } from './Moment';

type Props = {
  components: Array<MomentId>;
}

export const Moments = ({
  components,
}: Props) => {
  const {
    formatMessage,
  } = useIntl();

  const [
    isModalVisible,
    setIsModalVisible,
  ] = useState(false);

  const [
    selectedItem,
    setSelectedItem,
  ] = useState<MomentId>();

  const handleOpenModal = useCallback((item: MomentId) => {
    setSelectedItem(item);
    setIsModalVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const increments = [
    6,
    3,
    3,
  ];
  const backgroundColors = [
    Color.BLUE,
    Color.GREEN,
    Color.YELLOW,
  ];

  const result = useMemo(() => (insertVariantCardsMoments({
    array: components,
    backgroundColors,
    increments,
  }) as Array<MomentId>), [components]);

  return (
    <>
      <Wrapper>
        {result?.map((item, key) => (
          <Moment
            components={components}
            handleOpenModal={handleOpenModal}
            item={item}
            index={key}
            key={item.id || `${item.attributes?.backgroundColor}_${key}`}
          />
        ))}
        {selectedItem && (
          <Modal
            isOpen={isModalVisible}
            onRequestClose={closeModal}
            image={getBackendImage(
              selectedItem?.attributes?.image?.data.attributes.url
            )}
            title={selectedItem?.attributes?.popupTitle}
            content={selectedItem?.attributes?.text}
          />
        )}
      </Wrapper>
      <ButtonWrapper>
        <ViewMoreButton
          renderAs={Link}
          href={routePaths.moments}
          label={formatMessage(globalMessages.viewMore)}
          variant={ButtonVariant.GHOST}
        />
      </ButtonWrapper>
    </>
  );
};
