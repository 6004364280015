import { theme } from '@/themes/main';

import {
  Wrapper,
} from './About.styles';
import { AboutItem } from './AboutItem';
import { AboutItemInterface } from '../sections.types';

const aboutColors = [
  theme.colors.mainColors.green,
  theme.colors.mainColors.blue,
  theme.colors.mainColors.yellow,
];

interface AboutProps {
  components: Array<AboutItemInterface>;
}

export const About = ({
  components,
}: AboutProps) => (
  <Wrapper>
    {components.map(({
      image,
      title,
      longText,
      id,
    }, index) => (
      <AboutItem
        key={id}
        color={aboutColors[index]}
        image={image}
        title={title}
        longText={longText}
        index={index}
      />
    ))}
  </Wrapper>
);
