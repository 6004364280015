/* stylelint-disable no-descending-specificity */
import styled from 'styled-components';

import { IconButton } from '@/core/components/IconButton';
import {
  BodySmall,
  H3,
} from '@/core/components/Typography/Typography.styles';
import { ThemeDirection } from '@/core/constants/constants';

import { breakpoint } from '@/themes/breakpoints';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 62px 0;
`;

export const Icon = styled(IconButton)`
  background-color: inherit;

  @media ${breakpoint.md} {
    &:hover {
      background-color: ${({ theme: { colors } }) => colors.black} !important;
      transition: 400ms;
    }
  }
`;

export const ImageWrapper = styled.figure`
  position: absolute;
  right: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'auto' : '50px')};
  bottom: 0;
  left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? '50px' : 'auto')};
  width: auto;
  height: 185px;
  margin-bottom: 0;
  clip-path: polygon(-100vw -50%, 100vw -50%, 100vw 100%, -100vw 100%);

  @media ${breakpoint.sm} {
    right: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'auto' : '150px')};
    left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? '150px' : 'auto')};
  }

  @media ${breakpoint.md} {
    right: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? '400px' : 'auto')};
    left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'auto' : '400px')};
    z-index: 1;
    visibility: hidden;
    transform: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'rotate(10deg)' : 'rotate(-20deg)')} translateY(-75px) scale(2.5);
  }

  @media ${breakpoint.lg} {
    right: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? '500px' : 'auto')};
    left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'auto' : '500px')};
  }
`;

export const InsightsImage = styled.img`
  opacity: 100;
  width: 100%;
  height: 110%;
  transform: rotate(10deg) translateY(20px);

  @media ${breakpoint.md} {
    opacity: 0;
  }
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  margin: 0 -32px;
  padding: 0 32px;

  &:nth-child(3n + 3) {
    background-color: ${({ theme: { colors } }) => colors.yellow[100]};
  }

  &:nth-child(3n + 1) {
    background-color: ${({ theme: { colors } }) => colors.decorativeBlue[200]};
  }

  &:nth-child(3n + 2) {
    background-color: ${({ theme: { colors } }) => colors.green[100]};
  }

  @media ${breakpoint.sm} {
    padding: 0 123px;
  }

  @media ${breakpoint.md} {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -80px;
    padding: 60px 180px;
    transition: 400ms;

    &:hover ${Icon} {
      border-color: transparent;
      background-color: ${({ theme: { colors } }) => colors.neutral[200]};
    }

    &:hover ${InsightsImage} {
      visibility: visible;
      opacity: 1;
      transition: 400ms;
    }

    &:hover::after {
      content: "";
      position: absolute;
      top: 0;
      right: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? '0' : 'auto')};
      left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'auto' : '0')};
      z-index: 0;
      display: block;
      width: 546px;
      height: 100%;
      background-size: contain;
      background-repeat: repeat;
      background-position-x: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'left' : 'right')};
      transition: 400ms;
    }

    &:nth-child(3n + 3) {
      background-color: ${({ theme: { colors } }) => colors.neutral[100]};

      &:hover {
        background-color: ${({ theme: { colors } }) => colors.yellow[100]};

        &::after {
          background-image: url("/assets/images/vector_yellow.svg");
        }
      }
    }

    &:nth-child(3n + 1) {
      background-color: ${({ theme: { colors } }) => colors.neutral[200]};

      &:hover {
        background-color: ${({ theme: { colors } }) => colors.decorativeBlue[200]};

        &::after {
          background-image: url("/assets/images/vector_blue.svg");
        }
      }
    }

    &:nth-child(3n + 2) {
      background-color: ${({ theme: { colors } }) => colors.neutral[300]};

      &:hover {
        background-color: ${({ theme: { colors } }) => colors.green[100]};

        &::after {
          background-image: url("/assets/images/vector_green.svg");
        }

        &:nth-last-child(1) {
          clip-path: polygon(-100vw -50%, 100vw -50%, 100vw 100%, -100vw 100%);
        }
      }
    }
  }

  @media ${breakpoint.lg} {
    padding: 80px 220px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      z-index: -1;
      display: block;
      width: 200vw;
      height: 100%;
      transition: 400ms;
    }

    &:hover::after {
      right: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? 'auto' : '1290px')};
      left: ${({ theme }) => (theme.direction === ThemeDirection.LTR ? '1290px' : 'auto')};
      width: 100%;
    }

    &:nth-child(3n + 3) {
      &::before {
        background-color: ${({ theme: { colors } }) => colors.neutral[100]};
      }

      &:hover::before {
        background-color: ${({ theme: { colors } }) => colors.yellow[100]};
      }
    }

    &:nth-child(3n + 1) {
      &::before {
        background-color: ${({ theme: { colors } }) => colors.neutral[200]};
      }

      &:hover::before {
        background-color: ${({ theme: { colors } }) => colors.decorativeBlue[200]};
      }
    }

    &:nth-child(3n + 2) {
      &::before {
        background-color: ${({ theme: { colors } }) => colors.neutral[300]};
      }

      &:hover::before {
        background-color: ${({ theme: { colors } }) => colors.green[100]};
      }
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  @media ${breakpoint.sm} {
    padding-top: 30px;
  }

  @media ${breakpoint.md} {
    width: 40%;
    padding: 0 0 40px;
  }

  @media ${breakpoint.lg} {
    width: 50%;
  }
`;

export const Title = styled.h3`
  ${H3}
  margin-bottom: 15px;
  font-family: ${({ theme: { typography } }) => typography.fontFamily.headline};
`;

export const Description = styled.div`
  ${BodySmall}
`;
