import styled from 'styled-components';

import {
  BodyMedium,
  H3,
} from '@/core/components/Typography/Typography.styles';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const SliderItemWrapper = styled.div`
  flex-shrink: 0;
  width: 100vw;

  @media ${breakpoint.sm} {
    width: calc(100vw - 2 * ${sizes.tablet.margin}px);
  }

  @media ${breakpoint.md} {
    width: calc(100vw - 2 * ${sizes.desktopLarge.margin}px);
  }

  @media ${breakpoint.lg} {
    width: var(--max-margin-wrapper-width);
  }
`;

export const Wrapper = styled.div< { image: string } >`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 803px;
  background-image: linear-gradient(black, black), url(${({ image }) => image});
  background-position-x: center;
  background-position-y: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: saturation;

  @media ${breakpoint.sm} {
    height: 785px;
    margin: 0;
    background-position-y: 300px;
  }

  @media ${breakpoint.md} {
    height: 896px;
  }
`;

export const Tile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: min(344px, calc(100vw - 32px));
  margin: 0 16px;
  padding: 40px 24px;
  background-color: ${({ theme: { colors } }) => colors.white};

  @media ${breakpoint.sm} {
    width: 522px;
    min-width: auto;
    margin: 0;
    padding: 80px 100px;
  }

  @media ${breakpoint.md} {
    width: 628px;
  }
`;

export const Title = styled.h3`
  ${H3}
  margin: 0;
  font-family: var(--executive-website-heading-font-family);
  text-align: center;
`;

export const Subtitle = styled.p`
  ${BodyMedium}
  margin-top: 12px;
  margin-bottom: 40px;
  color: ${({ theme: { colors } }) => colors.neutral[700]};
  text-align: center;

  @media ${breakpoint.sm} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

export const LogoWrapper = styled.div`
  max-width: 90px;
  max-height: 90px;
  margin-bottom: 24px;
`;

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;
