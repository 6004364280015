import { Fragment } from 'react';

import { InsightsItem } from './InsightsItem';
import { InsightsItemInterface } from '../sections.types';

interface InsightsProps {
  components: Array<InsightsItemInterface>;
}

export const Insights = ({
  components,
}: InsightsProps) => (
  <>
    {components.map(({
      id,
      title,
      description,
      link,
      image,
    }) => (
      <Fragment key={id}>
        <InsightsItem
          title={title}
          description={description}
          link={link}
          image={image}
        />
      </Fragment>
    ))}
  </>
);
