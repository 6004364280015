import { About } from '@/features/Sections/About';
import { Moments } from '@/features/Sections/Moments';
import { FeaturedArticles } from '@/features/Sections/FeaturedArticles';
import { Insights } from '@/features/Sections/Insights';
import { Quote } from '@/features/Sections/Quote';
import { MomentsGallery } from '@/features/Sections/MomentsGallery';
import { AboutPageSection } from '@/features/Sections/AboutPageSection';
import {
  AboutItemInterface,
  AboutPageItemInterface,
  InsightsItemInterface,
  MomentsGalleryInterface,
  QuoteInterface,
  SectionContent,
  SectionItems,
  Sections,
  SliderItemInterface,
  VideoItemInterface,
} from '@/features/Sections/sections.types';
import { MomentId } from '@/features/Moments/moments.types';
import { Slider } from '@/features/Sections/Slider';
import { FeaturedArticleItemData } from '@/features/Articles/Articles.types';
import { Video } from '@/features/Sections/Video';

export const renderSectionComponent = (
  sectionName: Sections, items?: SectionItems, content?: SectionContent
) => {
  switch (sectionName) {
    case Sections.ABOUT:
      return <About components={items as Array<AboutItemInterface>} />;
    case Sections.ABOUT_PAGE_ITEM:
      return <AboutPageSection {...content as AboutPageItemInterface} />;
    case Sections.MOMENTS:
      return <Moments components={items as Array<MomentId>} />;
    case Sections.MOMENTS_GALLERY:
      return <MomentsGallery {...content as MomentsGalleryInterface} />;
    case Sections.FEATURED_ARTICLES:
      return <FeaturedArticles components={items as Array<FeaturedArticleItemData>} />;
    case Sections.INSIGHTS:
      return <Insights components={items as Array<InsightsItemInterface>} />;
    case Sections.QUOTE:
      return <Quote {...content as QuoteInterface} />;
    case Sections.SLIDER:
      return <Slider components={items as Array<SliderItemInterface>} />;
    case Sections.VIDEO:
      return <Video components={items as Array<VideoItemInterface>} />;
    default:
      return '';
  }
};

