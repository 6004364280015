import {
  Banner,
  Color,
  DataType,
  Image,
  Meta,
  Nullable,
  PageData,
} from '@/core/interfaces/common';

import { MomentId } from '@/features/Moments/moments.types';
import { FeaturedArticleItemData } from '@/features/Articles/Articles.types';

export enum Sections {
  ABOUT = 'sections.about',
  ABOUT_PAGE_ITEM = 'sections.about-page-item',
  FEATURED_ARTICLES = 'sections.featured-articles',
  INSIGHTS = 'sections.insights',
  SLIDER = 'sections.slider',
  VIDEO = 'sections.video',
  MOMENTS = 'sections.moments',
  MOMENTS_GALLERY = 'sections.moments-gallery',
  QUOTE = 'sections.quote',
}

export type TitleAlign = 'start' | 'end' | 'center';

export type SectionBackgroundColor = 'white' | 'grey';

export interface SectionDetails {
  id: number;
  title: string;
  subtitle: string;
  link: string;
  backgroundColor: SectionBackgroundColor;
  align?: TitleAlign;
}

export interface SectionInterface {
  id: number;
  __component: Sections;
  details?: SectionDetails;
  content?: SectionContent;
  items?: SectionItems;
}

export interface SectionConfig extends PageData {
  sections: Array<SectionInterface>;
  banner: Banner;
  meta: Nullable<Meta>;
}

/*
  Interfaces below describe all items that come as an array of items (SectionItems)
  or content (SectionContent) for sections and should correspond with strapi data structure
  for each section
*/

export interface AboutItemInterface {
  id: number;
  image: Image;
  title: string;
  longText: string;
  color: string;
}

export interface InsightsItemInterface {
  id: number;
  title: string;
  description: string;
  link: string;
  image: Image;
}

export interface QuoteInterface {
  id: number;
  text: string;
  author: string;
}

export interface SliderItemInterface {
  id: string;
  title: string;
  description: string;
  link: string;
  backgroundImage: Image;
  logo: Image;
}

export interface VideoItemInterface {
  id: string;
  article: { data: Nullable<DataType<{ slug: string }>> };
  description: string;
  subtitle: Nullable<string>;
  title: string;
  videoEmbed: string;
}

export interface MomentsGalleryInterface {
  data: Array<MomentId>;
}

export interface AboutPageItemInterface {
  id: number;
  content: string;
  isReversed: boolean;
  image: Image;
  header: string;
  description: string;
  backgroundColor: Color;
}

export type SectionItems =
  Array<AboutItemInterface> |
  Array<InsightsItemInterface> |
  Array<SliderItemInterface> |
  Array<VideoItemInterface> |
  Array<MomentId> |
  Array<FeaturedArticleItemData>;

export type SectionContent =
  QuoteInterface |
  AboutPageItemInterface |
  MomentsGalleryInterface;
