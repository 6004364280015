import { css } from 'styled-components';

import { Color } from '@/core/interfaces/common';

export const backgroundVariant = ({ $color }: { $color: Color }) => {
  switch ($color) {
    case Color.BLUE:
      return css`
        background: ${({ theme }) => theme.colors.decorativeBlue[200]};
      `;
    case Color.GREEN:
      return css`
        background: ${({ theme }) => theme.colors.green[100]};
      `;
    case Color.GREY:
      return css`
        background: ${({ theme }) => theme.colors.neutral[100]};
      `;
    case Color.YELLOW:
    default:
      return css`
        background: ${({ theme }) => theme.colors.yellow[100]};
      `;
  }
};
