import { useIntl } from 'react-intl';

import { Dot } from '@/core/components/Icons';
import { FormattedDate } from '@/core/components/FormattedDate';
import { globalMessages } from '@/core/messages/global.messages';
import { Nullable } from '@/core/interfaces/common';

import {
  RowContainer,
  RowText,
} from './ArticleCaption.styles';

type ArticleCaptionProps = {
  topic?: string;
  date: string;
  readingTime?: Nullable<number>;
};

export const ArticleCaption = ({
  topic,
  date,
  readingTime,
}: ArticleCaptionProps) => {
  const { formatMessage } = useIntl();

  return (
    <RowContainer>
      {topic && (
        <>
          <RowText>
            {topic}
          </RowText>
          <Dot />
        </>
      )}
      <RowText>
        <FormattedDate date={date} />
      </RowText>
      {readingTime && (
        <>
          <Dot />
          <RowText>
            {formatMessage(globalMessages.readTime, {
              time: readingTime,
            })}
          </RowText>
        </>
      )}
    </RowContainer>
  );
};
