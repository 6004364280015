import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';

import { MarginWrapper } from '@/core/components/MarginWrapper';
import { ThemeDirection } from '@/core/constants/constants';
import {
  ArrowLeft,
  ArrowRight,
} from '@/core/components/Icons';
import { globalMessages } from '@/core/messages/global.messages';

import { SectionInterface } from '@/features/Sections/sections.types';

import { renderSectionComponent } from '@/utils/renderSectionComponent';

import { theme as appTheme } from '@/themes/main';

import { ButtonVariant } from '../Button/Button.types';
import {
  ReadMoreButton,
  Subheader,
  Title,
  TitleWrapper,
  Wrapper,
} from './Section.styles';

export const BackgroundColor = {
  darkGrey: appTheme.colors.neutral[200],
  grey: appTheme.colors.neutral[100],
  white: appTheme.colors.white,
};

export const Section = ({
  details,
  items,
  content,
  __component,
}: SectionInterface) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const {
    title,
    subtitle,
    link,
    backgroundColor = 'white',
    align,
  } = details || {};

  return (
    <Wrapper
      backgroundColor={BackgroundColor[backgroundColor]}
    >
      <MarginWrapper>
        {subtitle && (
          <Subheader
            hasLink={!!link}
            align={align}
          >
            {subtitle}
          </Subheader>
        )}
        {title && (
          <TitleWrapper
            hasLink={!!link}
            align={align || 'center'}
          >
            <Title>{title}</Title>
            {link && (
              <ReadMoreButton
                renderAs="a"
                variant={ButtonVariant.GHOST}
                endIcon={theme.direction === ThemeDirection.LTR ? <ArrowRight /> : <ArrowLeft />}
                label={formatMessage(globalMessages.readMore)}
                href={link}
              />
            )}
          </TitleWrapper>
        )}
        { (items || content) && renderSectionComponent(__component, items, content) }
      </MarginWrapper>
    </Wrapper>
  );
};
