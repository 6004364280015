import styled from 'styled-components';
import Link from 'next/link';

import { IconButton } from '@/core/components/IconButton';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const Wrapper = styled.div`
  position: relative;
`;

export const ScrollWrapper = styled.div`
  overflow: scroll;
  display: flex;
  gap: 8px;
  width: calc(100% + ${sizes.phone.margin}px);
  padding-inline-end: ${sizes.phone.margin}px;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;

  div {
    scroll-snap-align: start;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${breakpoint.sm} {
    gap: 24px;
    width: calc(100% + ${sizes.tablet.margin}px);
    padding-inline-end: ${sizes.tablet.margin}px;
  }

  @media ${breakpoint.md} {
    gap: 24px;
    width: calc(100% + ${sizes.desktop.margin}px);
    padding-inline-end: ${sizes.desktop.margin}px;
  }

  @media ${breakpoint.lg} {
    gap: 24px;
    width: calc(100% + ${sizes.desktopLarge.margin}px);
    padding-inline-end: ${sizes.desktopLarge.margin}px;
  }
`;

export const MiniatureWrapper = styled.div`
  flex-shrink: 0;
  width: 256px;
  gap: 8px;

  @media ${breakpoint.sm} {
    width: 306px;
    gap: 24px;
  }

  @media ${breakpoint.md} {
    width: 533px;
  }
`;

const ScrollButton = styled(IconButton)`
  position: absolute;
  top: 21%;
  z-index: 2;

  @media ${breakpoint.sm} {
    top: 23%;
  }

  @media ${breakpoint.md} {
    top: 31%;
  }
`;

export const ScrollLeftButton = styled(ScrollButton)`
  left: 0;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

export const ScrollRightButton = styled(ScrollButton)`
  right: 0;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

/* stylelint-disable order/properties-order */
export const ArticleLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
`;
