import React, { Fragment } from 'react';

import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';

import { MomentId } from '@/features/Moments/moments.types';

import { getBackendImage } from '@/utils/helpers';

import {
  ImageDescription,
  MomentImage,
  SingleBlockContainer,
  StyledMomentVectorCard,
} from './Moment.styles';

type MomentProps = {
  components: Array<MomentId>;
  handleOpenModal: (item: MomentId) => void;
  item: MomentId;
  index: number;
}

export const Moment = ({
  handleOpenModal,
  components,
  item,
  index,
}: MomentProps) => {
  const { isTabletOrAbove } = useGlobalContext();

  const {
    title,
    image,
  } = item?.attributes || {};

  //  I've decided to go with js rather than with css because styled components
  //  had problems when working with multiple `nth-childrens` and variables combined

  const itemMarginStyling: { marginTop?: number | string } = {};

  if (index > 0) {
    const previousTitle = components[index + 1]?.attributes?.title;

    if (title || !previousTitle) {
      itemMarginStyling.marginTop = 0;
    }
  }

  return (
    <>
      {item?.attributes?.backgroundColor ?
        (
          <StyledMomentVectorCard color={item.attributes?.backgroundColor} />
        ) :
        (
          <SingleBlockContainer
            onClick={() => handleOpenModal(item)}
            key={index}
            style={isTabletOrAbove ? itemMarginStyling : undefined}
          >
            <MomentImage
              src={getBackendImage(image?.data?.attributes?.url)}
              alt={image?.data?.attributes?.alternativeText || title}
            />
            <ImageDescription>{title}</ImageDescription>
          </SingleBlockContainer>
        )
        }
    </>
  );
};

