import styled from 'styled-components';

import { Wrapper as MomentsWrapper } from '@/features/Sections/Moments';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const Grid = styled(MomentsWrapper)`
  margin-top: 24px;
  margin-bottom: 24px;

  @media ${breakpoint.sm} {
    grid-template-columns: repeat(5, 1fr);
    gap: clamp(
      21px,
      (100vw - ${sizes.tablet.minWidth}px) / ${sizes.desktopLarge.minWidth},
      24px
    );
  }
`;

export const Moment = styled.div`
  &:nth-child(2) {
    margin-top: 24px;

    @media ${breakpoint.sm} {
      margin-top: 8px;
    }
  }

  &:nth-child(3) {
    margin-top: -24px;

    @media ${breakpoint.sm} {
      margin-top: -8px;
    }
  }

  &:nth-child(4) {
    margin-top: 0;

    @media ${breakpoint.sm} {
      margin-top: -24px;
    }
  }

  &:nth-child(5) {
    display: none;

    @media ${breakpoint.sm} {
      display: block;
      margin-top: 0;
    }
  }
`;

