import { QuoteInterface } from '@/features/Sections/sections.types';

import {
  Sentence,
  SentenceWrapper,
  Signature,
  Wrapper,
} from './Quote.styles';

export const Quote = ({
  text,
  author,
}: QuoteInterface) => (
  <Wrapper>
    <SentenceWrapper>
      <Sentence>
        {text}
      </Sentence>
      {author && (
        <Signature>{`- ${author}`}</Signature>
      )}
    </SentenceWrapper>
  </Wrapper>
);
