import styled from 'styled-components';

import {
  BodySmall,
  Quote,
} from '@/core/components/Typography/Typography.styles';
import { ThemeDirection } from '@/core/constants/constants';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  @media ${breakpoint.md} {
    padding: 80px 0;
  }
`;

export const SentenceWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-inline-start: 49px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? 'auto' : '-49px')};
    left: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? '-49px' : 'auto')};
    display: flex;
    width: 25px;
    height: 20px;
    background-image: url("/assets/images/quote.svg");
    background-size: cover;
  }

  @media ${breakpoint.sm} {
    max-width: 587px;
    margin-inline-start: 65px;

    &::before {
      top: -20px;
      right: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? 'auto' : '-65px')};
      left: ${({ theme: { direction } }) => (direction === ThemeDirection.LTR ? '-65px' : 'auto')};
      width: 49px;
      height: 40px;
    }
  }

  @media ${breakpoint.md} {
    max-width: 911px;
  }

  @media ${breakpoint.lg} {
    max-width: 1231px;
  }
`;

export const Sentence = styled.div`
  ${Quote}
`;

export const Signature = styled.div`
  ${BodySmall}
  align-self: flex-end;
  margin-top: 10px;
  color: ${({ theme: { colors } }) => colors.neutral[700]};
`;
