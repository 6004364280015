import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { Nullable } from '@/core/interfaces/common';

import { AboutItemInterface } from '@/features/Sections/sections.types';

import { getBackendImage } from '@/utils/helpers';

import {
  AboutImage,
  LongText,
  TextWrapper,
  Title,
  Wrapper,
} from './AboutItem.styles';

interface AboutItemCustomProps {
  index: number;
}

type AboutItemProps = Omit<AboutItemInterface, 'id'> & AboutItemCustomProps;

export const AboutItem = ({
  image,
  title,
  color,
  longText,
  index,
}: AboutItemProps) => {
  const longTextRef = useRef<Nullable<HTMLParagraphElement>>(null);

  const [
    longTextHeight,
    setLongTextHeight,
  ] = useState(0);

  useEffect(() => {
    const resizeListener = () => {
      if (longTextRef.current) {
        setLongTextHeight(longTextRef.current?.getBoundingClientRect()?.height || 0);
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    setLongTextHeight(longTextRef.current?.getBoundingClientRect()?.height || 0);
  }, [longTextRef.current]);

  return (
    <Wrapper index={index}>
      <AboutImage
        src={getBackendImage(image.data?.attributes?.url)}
        alt={image.data?.attributes.alternativeText || `Reem Al Hashimy - ${title}`}
      />
      <TextWrapper
        offset={longTextHeight}
        color={color}
      >
        <Title>{title}</Title>
        <LongText ref={longTextRef}>
          { longText }
        </LongText>
      </TextWrapper>
    </Wrapper>
  );
};
