import {
  Meta,
  Story,
} from '@storybook/react';

import {
  IconProps,
} from './Icon.types';
import { ArrowBottom } from './ArrowBottom';
import { ArrowExtend } from './ArrowExtend';
import { ArrowExternal } from './ArrowExternal';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { Dot } from './Dot';
import { Down } from './Down';
import { Download } from './Download';
import { ExternalLink } from './ExternalLink';
import { Hamburger } from './Hamburger';
import { Lang } from './Lang';
import { Left } from './Left';
import { Linkedin } from './Linkedin';
import { Play } from './Play';
import { Right } from './Right';
import { Send } from './Send';
import { Spinner } from './Spinner';
import { YT } from './YT';
import { Close } from './Close';

export default {
  component: ArrowBottom,
  title: 'Components/Icons',
} as Meta;

export const ArrowBottomIcon: Story<IconProps> = args => (
  <ArrowBottom {...args} />
);

export const ArrowExtendIcon: Story<IconProps> = args => (
  <ArrowExtend {...args} />
);

export const ArrowExternalIcon: Story<IconProps> = args => (
  <ArrowExternal {...args} />
);

export const ArrowLeftIcon: Story<IconProps> = args => (
  <ArrowLeft {...args} />
);

export const ArrowRightIcon: Story<IconProps> = args => (
  <ArrowRight {...args} />
);

export const CloseIcon: Story<IconProps> = args => (
  <Close {...args} />
);

export const DotIcon: Story<IconProps> = args => (
  <Dot {...args} />
);

export const DownIcon: Story<IconProps> = args => (
  <Down {...args} />
);

export const DownloadIcon: Story<IconProps> = args => (
  <Download {...args} />
);

export const ExternalLinkIcon: Story<IconProps> = args => (
  <ExternalLink {...args} />
);

export const HamburgerIcon: Story<IconProps> = args => (
  <Hamburger {...args} />
);

export const LangIcon: Story<IconProps> = args => (
  <Lang {...args} />
);

export const LeftIcon: Story<IconProps> = args => (
  <Left {...args} />
);

export const LinkedinIcon: Story<IconProps> = args => (
  <Linkedin {...args} />
);

export const PlayIcon: Story<IconProps> = args => (
  <Play {...args} />
);

export const RightIcon: Story<IconProps> = args => (
  <Right {...args} />
);

export const SendIcon: Story<IconProps> = args => (
  <Send {...args} />
);

export const SpinnerIcon: Story<IconProps> = args => (
  <Spinner {...args} />
);

export const YTIcon: Story<IconProps> = args => (
  <YT {...args} />
);

