import { FormattedDateParts } from 'react-intl';

type FormattedDateProps = {
  date: string;
}

export const FormattedDate = ({ date }: FormattedDateProps) => (
  <FormattedDateParts
    value={new Date(date)}
    year="numeric"
    month="short"
    day="numeric"
  >
    {([
      month,,
      day,,
      year,
    ]) => (
      <>
        {month.value}
        {' '}
        {day.value}
        {' '}
        {year.value}
      </>
    )}
  </FormattedDateParts>

);
