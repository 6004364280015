import styled from 'styled-components';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  column-gap: ${sizes.phone.gap}px;
  margin-bottom: 20px;
  gap: 24px;

  @media ${breakpoint.sm} {
    column-gap: ${sizes.tablet.gap}px;
    margin-bottom: 40px;
  }

  @media ${breakpoint.md} {
    flex-direction: row;
    grid-template-columns: repeat(${sizes.desktop.columns}, minmax(0, 1fr));
    column-gap: ${sizes.desktop.gap}px;
  }

  @media ${breakpoint.lg} {
    column-gap: ${sizes.desktopLarge.gap}px;
  }
`;
