import { useTheme } from 'styled-components';
import {
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import Link from 'next/link';

import {
  ReadMoreButton,
  Subheader,
  Title,
  TitleWrapper,
} from '@/core/components/Section';
import {
  Button,
  ButtonVariant,
} from '@/core/components/Button';
import { ThemeDirection } from '@/core/constants/constants';
import {
  ArrowLeft,
  ArrowRight,
} from '@/core/components/Icons';
import { globalMessages } from '@/core/messages/global.messages';
import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';
import { paginationMessages } from '@/core/messages/pagination.messages';
import {
  IconButton,
  IconButtonSizes,
} from '@/core/components/IconButton';

import {
  Description,
  DescriptionWrapper,
  NavigationWrapper,
  VideoWrapper,
  Wrapper,
} from './Video.styles';
import { VideoItemInterface } from '../sections.types';

interface VideoProps {
  components: Array<VideoItemInterface>;
}

export const Video = ({ components }: VideoProps) => {
  const theme = useTheme();

  const { formatMessage } = useIntl();

  const {
    isDesktop,
  } = useGlobalContext();

  const [
    currentVideoIndex,
    setCurrentVideoIndex,
  ] = useState(0);

  const currentVideo = useMemo(() => components[currentVideoIndex], [
    components,
    currentVideoIndex,
  ]);
  const withNavigation = components.length > 1;

  return (
    <>
      <Subheader
        hasLink={!!currentVideo.article.data}
        align="start"
      >
        {currentVideo.subtitle}
      </Subheader>
      <TitleWrapper
        hasLink={!!currentVideo.article.data}
        align="start"
      >
        <Title>{currentVideo.title}</Title>
        {!!currentVideo.article.data && (
          <ReadMoreButton
            renderAs={Link}
            variant={ButtonVariant.GHOST}
            endIcon={theme.direction === ThemeDirection.LTR ? <ArrowRight /> : <ArrowLeft />}
            label={formatMessage(globalMessages.readMore)}
            href={`vision/${currentVideo.article.data.attributes.slug}`}
          />
        )}
      </TitleWrapper>
      <Wrapper>
        <VideoWrapper dangerouslySetInnerHTML={{ __html: currentVideo.videoEmbed }} />
        <NavigationWrapper>
          {withNavigation && (
            (() => {
              const prevButtonCommonProps = {
                'aria-label': 'Previous video',
                isDisabled: currentVideoIndex === 0,
                onClick: () => setCurrentVideoIndex(currentVideoIndex - 1),
              };

              return isDesktop ?
                (
                  <Button
                    startIcon={theme.direction === ThemeDirection.LTR ?
                      <ArrowLeft /> :
                      <ArrowRight />
                    }
                    variant={ButtonVariant.GHOST}
                    label={isDesktop ? formatMessage(paginationMessages.previous) : ''}
                    {...prevButtonCommonProps}
                  />
                ) :
                (
                  <IconButton
                    icon={theme.direction === ThemeDirection.LTR ? <ArrowLeft /> : <ArrowRight />}
                    variant={ButtonVariant.SECONDARY}
                    size={IconButtonSizes.SMALL}
                    ariaLabel="Previous video"
                    {...prevButtonCommonProps}
                  />
                );
            })()
          )}
          <DescriptionWrapper>
            <Description>
              {currentVideo.description}
            </Description>
          </DescriptionWrapper>
          {withNavigation && (
            (() => {
              const nextButtonCommonProps = {
                'aria-label': 'Next video',
                isDisabled: currentVideoIndex === components.length - 1,
                onClick: () => setCurrentVideoIndex(currentVideoIndex + 1),
              };

              return isDesktop ?
                (
                  <Button
                    endIcon={theme.direction === ThemeDirection.LTR ?
                      <ArrowRight /> :
                      <ArrowLeft />
                    }
                    variant={ButtonVariant.GHOST}
                    label={isDesktop ? formatMessage(paginationMessages.next) : ''}
                    {...nextButtonCommonProps}
                  />
                ) :
                (
                  <IconButton
                    icon={theme.direction === ThemeDirection.LTR ? <ArrowRight /> : <ArrowLeft />}
                    variant={ButtonVariant.SECONDARY}
                    size={IconButtonSizes.SMALL}
                    ariaLabel="Next video"
                    {...nextButtonCommonProps}
                  />
                );
            })()
          )}
        </NavigationWrapper>
      </Wrapper>
    </>
  );
};
